import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationCheckService } from 'src/app/services/auth/authentication-check.service';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';
import { ToastClientService } from 'src/app/services/toast/toast-client.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  
  loginForm;
  submitted : boolean = false;
  constructor(
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private router: Router,
    private formBuilder: FormBuilder,
    private toast: ToastClientService,
    private auth : AuthenticationCheckService

  ) {
    this.loginForm = formBuilder.group({
      user_name: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])],
    });
  }
  ngOnInit() {

  }

  get f() { return this.loginForm.controls; }


  login(value : any){
    this.submitted = true;
    console.log(this.loginForm)
    if(this.loginForm.invalid){
      return
    }

    localStorage.setItem("program",'1')

    let body = JSON.stringify({

     LoginForm: {
     username: value.user_name,
     password: value.password,
     }, 
     });
    this.apiService.post(this.apiConfig.login , body).subscribe((res: any)=>{
      console.log(res)
      localStorage.setItem("user_detail", JSON.stringify(res.body.data))
      let token = `${res.body.data.access_token}`;
      this.auth.saveAuth(token);
    
      this.router.navigate(["hubAdmin"]);
    })


     console.log(value)
  }



}


