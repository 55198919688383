
import { Injectable } from "@angular/core";


@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {

  
  // Staging
 baseUrl = 'https://api.akamai.bigcityvoucher.co.in/v2';


  //baseUrl = 'http://192.168.1.66/akamai_api/web/v2';


// login 
  login = this.baseUrl + "/login/login"

  //campaign
  //create-campaign
  createCampaign = this.baseUrl + "/hubadmin/create-campaign"
  getCampaignList = this.baseUrl + "/hubadmin/get-campaign"
  getSkuList = this.baseUrl + "/hubadmin/get-denomination"
  uploadVoucher = this.baseUrl + "/hubadmin/upload-voucher-code"
  getVouchersList = this.baseUrl + "/hubadmin/get-voucher-code"
  updateCampaign = this.baseUrl + "/hubadmin/update-campaign"
  topup = this.baseUrl + "/hubadmin/topup"
  getSkus = this.baseUrl + "/hubadmin/get-skus"
  uploadUsers = this.baseUrl + "/hubadmin/import-users-to-campaign"
  getUsersList = this.baseUrl + "/hubadmin/customers-list"
  updateVoucher=this.baseUrl + "/hubadmin/updatevouchercode"
  updateUser=this.baseUrl + "/hubadmin/blockuser"

  getRedemptionUsers=this.baseUrl + '/hubadmin/getmanualuploaduser';
  rejectReasons = this.baseUrl + '/redemption/rejection-reason';
  approveRejectRedemption=this.baseUrl + '/hubadmin/approvemanualupload';


  // Bank Mis
  getlistOfMIS = this.baseUrl + "/hubadmin/get-mis-list"
  getMISLineItems = this.baseUrl + "/hubadmin/get-line-item-by-mis-id"
  createMIS = this.baseUrl + "/hubadmin/create-mis"
  truncateMIS = this.baseUrl + "/hubadmin/delete-mis";
  downloadMIS = this.baseUrl + "/hubadmin/download-mis-by-id";
  bulkUtrUpload = this.baseUrl + "/hubadmin/bulk-utr-upload";
  updateBankUtr = this.baseUrl + "/finance/update-utrbyid";
  editBankMis = this.baseUrl + "/hubadmin/edit-mis-by-id";

  // Payout Master
  payoutList = this.baseUrl + "/hubadmin/readpayoutmaster"
  readPayoutdetails = this.baseUrl + "/hubadmin/readpayoutdetail"
  generatePayout = this.baseUrl + "/hubadmin/generatepayout"

// agent Payout
  agentPayoutList  = this.baseUrl + "/finance/agentpayoutlist"
  agentPayoutListByID = this.baseUrl + "/finance/agentpayoutlistbyid"
  updateAgentUtr = this.baseUrl + "/hubadmin/updateutr";
  downloadAgentPayoutFile = this.baseUrl + "/hubadmin/downloadlineitembypoid"
  uploadAgentUTR = this.baseUrl + "/hubadmin/agentutruploadbulk"
  updateagentgst = this.baseUrl + "/hubadmin/updateagentgst"
  paidCase = this.baseUrl + "/finance/get-paid-cases"
  invoiceView = this.baseUrl + '/hubadmin/get-invoice-details'

  // Points
  PointsSummary = this.baseUrl + '/hubadmin/getpointlist'

   //Reports
   BcpVoucherRedmption=this.baseUrl+ '/report/uservouchercodereport';
   Ledger=this.baseUrl + '/report/stashfin-ledger';
   BalancePointsRedemption= this.baseUrl + '/hubadmin/balance-report';

}
export interface ApiConfigurationInterface { }